import React from "react"
import { GATSBY_CONTACT_EMAIL } from "gatsby-env-variables"
import { GATSBY_CONTACT_NUMBER } from "../../../env"

const Email = () => {
  return (
    <span>
      <a
        href={`mailto:${GATSBY_CONTACT_EMAIL}`}
        target="_blank"
        rel="noreferrer noopener"
        className="link has-text-primary has-text-weight-bold"
      >
        {GATSBY_CONTACT_EMAIL}
      </a>
    </span>
  )
}

const Mobile = () => {
  return (
    <span className="has-text-primary has-text-weight-bold">
      {GATSBY_CONTACT_NUMBER}
    </span>
  )
}

const Contact = () => {
  return (
    <span>
      <Email /> or <Mobile />
    </span>
  )
}

export { Email, Mobile, Contact }
