import Container from "layout/Container";
import SEO from "layout/SEO";
import { Email, Mobile } from "elements/Contact";
import * as React from 'react';
export default {
  Container,
  SEO,
  Email,
  Mobile,
  React
};