const publicFundingSupport = {
  publicAmountPhilHealth: "",
  publicAmountDSWD: "",
  publicAmountDOH: "",
  publicAmountPAGCOR: "",
  publicAmountPCSO: "",
  publicAmountOWWA: "",
  publicAmountOfficeOfThePresident: "",
  publicAmountOfficeOfTheMayorRepresentative: "",
  publicAmountSSSGSISLoan: "",
  publicAmountOfficeOfTheVicePresident: "",
  publicAmountOtherName: "",
  publicAmountOtherAmount: "",
}

const privateFundingSupport = {
  privateAmountCompanyHealthBenefit: "",
  privatePersonalHMOProvider: "",
  privateAmountPersonalHMO: "",
  privatePersonalInsuranceProvider: "",
  privateAmountPersonalInsurance: "",
  privateAmountFoundationsNGOs: "",
  privateFoundationsNGOsProvider: "",
  privateAmountPersonalBankLoansCredits: "",
  privateAmountOtherName: "",
  privateAmountOtherAmount: "",
}

const patientEnrollmentInitialValues = {
  firstName: "",
  lastName: "",
  middleInitial: "",
  emailAddress: "",
  mobileNumber: "",
  landline: "",
  birthday: {
    month: {
      value: "",
    },
    date: {
      value: "",
    },
    year: "",
  },

  contactedThrough: ["SMS"],

  hospitalClinicName: {
    value: null,
  },
  otherHospital: "",
  prescribingDoctor: "",
  prescribedMedicine: "",
  medicationStartDate: {
    month: {
      value: "",
    },
    date: {
      value: "",
    },
    year: "",
  },
  diagnosis: {
    value: "",
  },
  otherDiagnosis: "",
  diagnosisDate: {
    month: {
      value: "",
    },
    date: {
      value: "",
    },
    year: "",
  },
  withConfirmedHRCT: "",
  nextAppointmentDate: {
    month: {
      value: "",
    },
    date: {
      value: "",
    },
    year: "",
  },
  numberOfDependents: "",
  philHealthNumber: "",
  sssGSISNumber: "",
  tinNumber: "",
  consentAndAuth: [],
}

const patientDetailsInitialState = {
  firstName: "",
  lastName: "",
  middleInitial: "",
  email: "",
  mobileNumber: "",
  landline: "",
  deliveryAddress: {
    addressType: "",
    streetAddress: "",
    province: { value: "Metro Manila", label: "Metro Manila" },
    city: { value: "" },
    notes: "",
  },
  yearsOfResidency: "",
  civilStatus: {
    value: "",
  },
  numberOfDependents: "",
  philHealthNumber: "",
  sssGSISNumber: "",
  tinNumber: "",
}

const financialAssessmentInitialState = {
  patientEmploymentStatus: {
    value: "",
  },
  sourceOfIncome: [],
  patientMonthlyIncome: "",
  monthlyBusinessIncome: "",
  monthlyIncome: "",
  monthlyAverage: "",
  propertyMonthlyIncome: "",
  estateMonthlyIncome: "",
  investmentMonthlyAverage: "",
  sourceOfPension: "",
  monthlyPension: "",
  sourceOfRemittance: "",
  monthlyRemittance: "",
  sourceOfSupport: "",
  monthlySupport: "",
  otherSource: "",
  monthlyAmount: "",

  publicFundingSupport: [],
  ...publicFundingSupport,

  privateFundingSupport: [],
  ...privateFundingSupport,

  typeOfResidence: "",
  mortgagedMonthlyAmortization: "",
  monthlyRental: "",
  otherResidenceStatus: "",
  otherResidenceStatusMonthly: "",
  sizeOfResidence: "",
  hasVehicle: "",
  numberOfVehiclesOwned: "",
  kindsOfVehicleOwned: "",

  monthlyLiabilitiesAndExpenses: [
    { name: "Food and groceries", amount: "" },
    { name: "Education", amount: "" },
    { name: "Transportation", amount: "" },
    { name: "Mortgage, rent, and other housing-related expenses", amount: "" },
    {
      name: "Utilities and other bills (e.g. electricity and water bills)",
      amount: "",
    },
    {
      name: "Other expenses (e.g. clothing, salaries, and insurance premiums)",
      amount: "",
    },
  ],
}

const diagnosticSubsidyInitialState = {
  firstName: "",
  lastName: "",
  middleInitial: "",
  email: "",
  mobileNumber: "",
  birthday: {
    month: {
      value: "",
    },
    date: {
      value: "",
    },
    year: "",
  },
  prescribingDoctor: "",
  hospitalClinicName: {
    value: null,
  },
  otherHospital: "",
  partnerLaboratories: "",
  dateOfChestXray: {
    month: {
      value: "",
    },
    date: {
      value: "",
    },
    year: "",
  },
  dateOfPFT: {
    month: {
      value: "",
    },
    date: {
      value: "",
    },
    year: "",
  },
  consentAndAuth: [],
}

const modalInitialState = {
  isCard: false,
  isFullheight: false,
  isActive: false,
  content: null,
  heading: "",
  headerClass: null,
  hideCloseButton: false,
  background: {
    color: null,
    opacity: "100",
  },
  disableCloseModal: false,
}

const medicineAvailmentState = {
  desiredMedicine: {
    value: null,
  },
  receipts: [],
}

const receiptUploadState = {
  document: [],
  salesInvoiceNumber: "",
  subdistributor: {
    value: "",
  },
  purchaseDate: {
    month: { value: "" },
    date: { value: "" },
    year: "",
  },
  lineItems: [
    {
      isOrdered: [],
      lineItem: "Afatinib (Giotrif) 30mg Tablet",
      tabletsOrdered: "",
    },
    {
      isOrdered: [],
      lineItem: "Afatinib (Giotrif) 40mg Tablet",
      tabletsOrdered: "",
    },
  ],
}

export const initialState = {
  patientEnrollment: {},
  diagnosticSubsidy: {},
  isNewPatient: "",
  financialAssessmentPersonal: {},
  medicineAvailment: { ...medicineAvailmentState },
  receiptUpload: { ...receiptUploadState },
  patientDetails: {},
  medicineAssistance: {},
  financialDetails: { ...financialAssessmentInitialState },
  documents: [],
  faDeclaration: [],
  epharmacy: {
    firstName: "",
    lastName: "",
    middleInitial: "",
    email: "",
    mobileNumber: "",
    changeFor: "",
    secondaryContactPerson: {
      fullName: "",
      mobileNumber: "",
    },
    landline: "",
    deliveryAddress: {
      addressType: "",
      streetAddress: "",
      province: { value: "", label: "" },
      city: { value: "" },
      notes: "",
    },
    paymentMethod: "Bank Transfer or Deposit",
    medicines: [],
  },
  toast: {
    isActive: false,
    message: null,
    color: null,
  },
  consentAndAuth: [],
}
