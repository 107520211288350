module.exports = {
  GATSBY_WEBSITE_URL: "bihope",
  GATSBY_HASURA_GRAPHQL_URL:
    "https://mg-notifications.herokuapp.com/v1/graphql",
  GATSBY_HASURA_GRAPHQL_ADMIN_SECRET: "KennyRogers2017",
  GATSBY_AES_KEY:
    "cHVsc2VwbGFjZWdyaXRodWJoZWFkc3BhY2V2aXNpb25ib2FyZHRlY2hkZWNr",
  GATSBY_FIREBASE_DOCTOR_ROLE_ID: "CjysZuKQYpgXncdMVudA",
  GATSBY_ZENDESK_API_URL: "https://mgbm.zendesk.com/api/v2",
  GATSBY_ZENDESK_API_KEY: "R6ASc3FEBtXto2uSaHnzx0EG04Z78z0f2kmYtU6b",
  GATSBY_ZENDESK_EMAIL: "pap@medgrocer.com",
  GATSBY_MINIMUM_FILES_UPLOADED: 6,
  GATSBY_CONTACT_NUMBER: "0917 814 0122",
  GATSBY_CONTACT_EMAIL: "bi-hope@medgrocer.com",
  GATSBY_FINANCIAL_ASSESSMENT_ZENDESK_ID: "900009245986",
  GATSBY_GIOTRIF_ZENDESK_FIELD: "1900000502528",

  GATSBY_PATIENT_ENROLLMENT_KEY:
    "U2FsdGVkX18zDYUKHD/cvDtFoene1WXs1uCM+w8GqkFLtYFBdKziPd0qHhCmX3sd",
  GATSBY_PATIENT_ENROLLMENT_VALUE:
    "U2FsdGVkX18M3dh67gbOSWnzqrIpH1jotdAhC7Gvega9gafk21UcGMXU+7W/e/zs",
  GATSBY_FINANCIAL_ASSESSMENT_KEY:
    "U2FsdGVkX1/LtGfiO9p6N6pa3obgY6OFTGuzbXNsQHGXdd7KjB7Wn6jMkhPlGSMC",
  GATSBY_FINANCIAL_ASSESSMENT_VALUE:
    "U2FsdGVkX1+JoiEiO8nyAsHq4yW5Aj9SLId0Chw4ptOCkNZ/1snZbaMtg+QNdalb",
  GATSBY_DIAGNOSTIC_SUBSIDY_KEY:
    "U2FsdGVkX1/RF0QLlySRfYZ7v0APYvvg7cGpNMNnKID51lPqLsmWVz3rHVUBllWo",
  GATSBY_DIAGNOSTIC_SUBSIDY_VALUE:
    "U2FsdGVkX19xeYeMEeKKKLquyIzep28NQQ1Rw4Rk/srvDfJ9MUK4gQDDI2eXRNSQ",
  GATSBY_EPHARMACY_KEY:
    "U2FsdGVkX19j4qLDPy4jsWPF6jMsi2dPkpb6/3WTsahE0iaoeoUk4eKZF6J63QNI",
  GATSBY_EPHARMACY_VALUE:
    "U2FsdGVkX1/mRmCZiIG+PZkTzqzB1xVTaOuoKNRUGKe7V2pr1HQdm7Hn7vQrCYuc",
  GATSBY_SITE_NAVIGATION_HOPE: "U2FsdGVkX19fNj1mhm+6QGk6aiLO8gAE/nxtC8sFjPk=",
  GATSBY_SITE_NAVIGATION_GIOTRIF:
    "U2FsdGVkX18md2IHn3DYU0HXIhjGrZSneg+wQD+N0pw=",
  GATSBY_SITE_NAVIGATION_DS:
    "U2FsdGVkX1+u6uxDZ4jLpDb1bWWyV9plx3b2vPxlDsD0tWQkJN4hDdswgFPBysAp",

  GATSBY_GIOTRIF_PEF_KEY: "U2FsdGVkX1/qac4LbCPbCxH23IURErjNE6Q9S3ivyrE=",
  GATSBY_GIOTRIF_PEF_VALUE:
    "U2FsdGVkX19vPhbMHpGZ7WFBGO3IvS+Nn2ELMpakHXtzkWbSrheR2KuTBAbdNG0q",
  GATSBY_GIOTRIF_MA_KEY: "U2FsdGVkX1/c6IwIpPnaeZhGMpId7qrOprGOiGQTiUI=",
  GATSBY_GIOTRIF_MA_VALUE:
    "U2FsdGVkX1/ovrYl7zgcmra/wHlK7jcwkqT2nsboY1u8X4aaA0L/1j2or8Tw4x1Y",
  GATSBY_GIOTRIF_AVAILMENT_KEY:
    "U2FsdGVkX1/OGUuWJLYvRu+SBe6phVr+c8yS63FSEbkQJ9meiAsPDcTpOsQdsRgL",
  GATSBY_GIOTRIF_AVAILMENT_VALUE:
    "U2FsdGVkX193FWtYwjWE8ztdc1eDDSFUmwstIf7S5DkMhkNNaGxQOzhtdD6KDvOW",

  GATSBY_ENABLED_SITES: "HOPE, Giotrif",
  GATSBY_OFEV_HALF_BOX: "enabled",
  GATSBY_PURCHASE_DATE_YEAR: 2021,
  GATSBY_THUMBNAIL_URL:
    "https://firebasestorage.googleapis.com/v0/b/medgrocer-28d8f.appspot.com/o/link-thumbnail.png?alt=media&token=b5e1b318-3b7a-4144-b974-6ec23b13d47d",
  GATSBY_BSKIES_EMAIL: "BI.SKIES.ph@boehringer-ingelheim.com",
}
