import React, { useReducer } from "react"
import { AppReducer } from "./AppReducer"
import { initialState } from "./utils/initialState"
import Modal from "elements/Modal"
import Toast from "layout/Toast"

const AppContext = React.createContext(initialState)

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, { ...initialState })

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <Modal
        isModalActive={state?.modal?.isActive}
        isFullheight={state?.modal?.isFullheight}
        closeModal={() => dispatch({ type: "HIDE_MODAL" })}
        heading={state?.modal?.heading}
        modalHeaderClass={state?.modal?.headerClass}
        modalBackground={state?.modal?.background}
        hideCloseButton={state?.modal?.hideCloseButton}
        isCard={state?.modal?.isCard}
        disableCloseModal={state?.modal?.disableCloseModal}
      >
        {state?.modal?.content}
      </Modal>
      <Toast
        isActive={state.toast.isActive}
        message={state.toast.message}
        color={state.toast.color}
      />
      {children}
    </AppContext.Provider>
  )
}

export { AppContext, AppProvider }
